const psychFormJson = {
  title: "Clinician Onboarding Form",
  logoPosition: "right",
  showProgressBar: "both",
  progressBarType: "questions",
  completedHtml: "Your changes have been saved.",
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "text",
          name: "firstName",
          maxWidth: "50%",
          startWithNewLine: false,
          title: "First Name",
          isRequired: true,
          autocomplete: "name",
        },
        {
          type: "text",
          name: "lastName",
          maxWidth: "50%",
          startWithNewLine: false,
          title: "Last Name",
          isRequired: true,
          autocomplete: "family-name",
        },
        {
          type: "text",
          name: "preferredName",
          title: "Preferred Name",
          isRequired: true,
        },
        {
          type: "checkbox",
          name: "pronouns",
          title: "Pronouns",
          isRequired: true,
          choices: [
            {
              value: "He",
              text: "He/Him",
            },
            {
              value: "She",
              text: "She/Her",
            },
            {
              value: "They",
              text: "Them/They",
            },
          ],
          otherText: "Other (please specify)",
        },
        {
          type: "radiogroup",
          name: "professionalDiscipline",
          title: "What is your professional discipline?",
          isRequired: true,
          choices: [
            "Psychologist",
            "Counsellor",
            "Provisional Psychologist (working towards full registration as a psychologist through a period of supervised practice)",
            "Accredited Mental Health Social Worker",
            "Accredited Mental Health Occupational Therapist",
            "Social Worker",
            "Clinical Hypnotherapist",
            "Hypnotherapist",
            "Play Therapist",
            "Clinical Neuropsychologist",
            "Allied Health Assistant",
            "Speech Therapist",
            "Occupational Therapist (non mental health accredited)",
            "Dietitian",
          ],
          showOtherItem: true,
          otherText: "Other (please specify)"
        },
        {
          type: "dropdown",
          name: "psychologyType",
          visibleIf: "{professionalDiscipline} = 'Psychologist'",
          title: "Please select your specialisation",
          requiredIf: "{professionalDiscipline} = 'Psychologist'",
          choices: [
            "General Psychology",
            "Clinical Psychology",
            "Clinical Neuropsychology",
            "Counselling Psychology",
            "Educational and Developmental Psychology",
            "Forensic Psychology",
            "Organisational Psychology",
            "Sport & Exercise Psychology",
            "Community Psychology",
          ],
        },
      ],
    },
    {
      name: "page2",
      elements: [
        {
          type: "checkbox",
          name: "service",
          title: "Service",
          isRequired: true,
          choices: [
            {
              value: "Assessments",
              text: "Psychological Assessments",
            },
            {
              value: "Psychology",
              text: "Psychological Therapy/Counselling",
            },
            { value: "Hypnotherapy", text: "Hypnotherapy" },
            { value: "Occupational Therapy", text: "Occupational Therapy" },
            {
              value: "Occupational Therapy Assessment",
              text: "Occupational Therapy Assessment",
            },
            { value: "Speech Therapy", text: "Speech Therapy" },
            { value: "Chiropractic", text: "Chiropractic" },
            { value: "Osteopathy", text: "Osteopathy" },
            { value: "Physiotherapy", text: "Physiotherapy" },
            { value: "Support Coordination", text: "Support Coordination" },
            { value: "Recovery Coaching", text: "Recovery Coaching" },
            {
              value: "School Leaver Employment Services (SLES)",
              text: "School Leaver Employment Services (SLES)",
            },
            { value: "Dietetics", text: "Dietetics" },
            { value: "Nutrition", text: "Nutrition" },
            { value: "Exercise Physiology", text: "Exercise Physiology" },
            { value: "Psychiatry", text: "Psychiatry" }
          ],
          otherText: "Other (please specify)",
        },
        {
          title:
            "Please select the type of occupational therapy you are seeking?",
          type: "checkbox",
          name: "occupationalTherapySubCategories",
          visibleIf: "{service} contains 'Occupational Therapy'",
          requiredIf: "{service} conntains 'Occupational Therapy'",
          choices: [
            "Sensory Processing",
            "Attention/Concentration/Self-regulation",
            "Toileting",
            "Self-care skills (e.g. hygiene, dressing)",
            "Fussy Eating",
            "Handwriting",
            "Learning challenges",
            "Acute Fractures",
            "Paediatric Injuries",
            "Surgical Tendons",
            "Post-op tendon repair",
            "Geographical barriers (People outside Townsville/remote areas)",
            "Nerve concerns",
            "Dislocation",
            "Ligament injuries",
            "Soft tissue injuries",
            "Repetitive conditions",
            "Arthritis",
          ],
        },
        {
          title:
            "Please select the type of occupational therapy assessment you are seeking?",
          type: "checkbox",
          name: "occupationalTherapyAssessmentSubCategories",
          visibleIf: "{service} contains 'Occupational Therapy Assessment'",
          requiredIf: "{service} contains 'Occupational Therapy Assessment'",
          choices: [
            "Developmental assessment",
            "Functional Capacity assessment",
            "Motor Skills assessment",
            "Learning assessment",
          ],
        },
        {
          type: "tagbox",
          name: "assessments",
          visibleIf: "{service} contains 'Assessments'",
          title: "Which assessments would you like?",
          requiredIf: "{service} contains 'Assessments'",
          choices: [
            "ASD Assessment",
            "ADHD Assessment",
            "Career and Vocational Assessment",
            "Compensation Assessment (workplace accident, medical negligence,victim of crime, motor vehicle)",
            "Functional Assessment",
            "Educational Assessment",
            "Immigration Assessment",
            "Impairment Assessment",
            "Intellectual/Cognitive Assessment",
            "Neuropsychological Assessment",
            "Disability Support Pension (DSP) Assessment",
            "Personality Assessment",
            "Autism Assessment",
            "Psychopathology Assessment",
            "Bariatric Pre-Surgery Assessment",
            "WPATH"
          ],
        },
        {
          type: "checkbox",
          name: "age",
          title: "What aged clients are you able to see?",
          isRequired: true,
          choices: [
            "0-2",
            "3-6",
            "7-9",
            "10-12",
            "13-15",
            "16-17",
            "18-25",
            "26-50",
            "51-65",
            "66-80",
            "81+",
          ],
        },
        {
          type: "checkbox",
          name: "mainReason",
          visibleIf: "{service} contains 'Psychology'",
          title: " What type of client referrals do you service?",
          requiredIf: "{service} contains 'Psychology'",
          choices: [
            "Mental Health",
            "General Health",
            "Neurodivergence",
            "Trauma/Harm",
            "Personal Problems and Life Stressors",
            "Gender",
            "Relationships",
            "Educational",
            "Addictions",
            "Work/Community",
            "Suicidal Thinking",
            "Self Harming Behaviours",
            "Behavioural Problems",
            "Legal",
            "Couples/Family Therapy",
          ],
          showOtherItem: true,
          colCount: 2,
        },
        {
          type: "tagbox",
          choices: ["Couples Therapy", "Family Therapy"],
          name: "therapySubCategories",
          visibleIf:
            "{mainReason} contains ['Couples/Family Therapy'] and {service} = 'Psychology'",
          title: "Please select the types of therapy you are seeking?",
          requiredIf:
            "{mainReason} contains ['Couples/Family Therapy'] and {service} = 'Psychology'",
          choices: [
            {
              value: "Couples Therapy",
              text: "Couples Therapy (only select if you'd like therapy with a partner)",
            },
            {
              value: "Family Therapy",
              text: "Family Therapy (only select if you'd like group therapy)",
            },
            "Neurodiverse Couples Therapy / Coaching",
          ],
        },
        {
          type: "tagbox",
          name: "mentalHealthSubCategories",
          visibleIf: "{mainReason} contains ['Mental Health']",
          title:
            "Please select which type of Mental Health issues you work with?",
          requiredIf: "{mainReason} contains ['Mental Health']",
          choices: [
            "Adjustment Disorder",
            "Anxiety & Phobias",
            "BiPolar Disorder",
            "Body Dysmorphia",
            "Depression",
            "Dissociative Identity Disorder",
            "Eating Disorders",
            "Hoarding Disorder",
            "Obsessive Compulsive Disorder (OCD)",
            "Panic Disorder",
            "Personality Disorders",
            {
              label: "Peri/post Natal depression/anxiety",
              value: "Post-natal Depression/Anxiety",
            },
            "Psychosis",
            "Schizophrenia",
            "Selective Mutism",
            "Situational Mutism",
            "Tourette’s Syndrome (Tic Disorder)",
            "Trichotillomania (hair pulling)",
            "Excoriation (Skin picking)",
          ],
        },
        {
          type: "tagbox",
          name: "neurodivergenceSubCategories",
          visibleIf: "{mainReason} contains ['Neurodivergence']",
          title:
            "Please select which type of Neurodivergence issues you work with?",
          requiredIf: "{mainReason} contains 'Neurodivergence'",
          choices: [
            "Attention Deficit Hyperactivity Disorder",
            "Autism Spectrum Disorder",
            "Autism",
          ],
          otherText: "Other (please specify)",
        },
        {
          type: "tagbox",
          name: "generalHealthSubCategories",
          visibleIf: "{mainReason} contains ['General Health']",
          title:
            "Please select which type of General Health issues you work with?",
          requiredIf: "{mainReason} contains ['General Health']",
          choices: [
            "Chronic Disease Management",
            "Genetic Counselling",
            "Health-related problems",
            "Anxiety about my health and physical symptoms",
            "Infertility Issues",
            "Memory Problems",
            "Pain Management",
            "Physical Disability",
            "Pre-cosmetic Surgery Assessment",
            "Perimenopause, menopause, postmenopause",
            "Rehab/Injury Counselling",
            "Sleeping problems",
            "Stress Management",
            "Weight Management",
            "Toileting problems (Encopresis; Enuresis)",
            "Chronic Illness",
            "General Health Issues",
          ],
        },
        {
          type: "tagbox",
          name: "traumaSubCategories",
          visibleIf: "{mainReason} contains ['Trauma/Harm']",
          title:
            "Please select which type of Trauma/Harm issues you work with?",
          requiredIf: "{mainReason} contains ['Trauma/Harm']",
          choices: [
            "Adult Survivor of Sexual Abuse",
            "Bullying",
            "Birth trauma",
            "Child Abuse",
            "Complex Developmental Trauma",
            "Complex PTSD",
            "Disaster Recovery",
            "Family Violence",
            "Medical trauma",
            "Post Traumatic Stress Disorder (PTSD)",
            "Self-harm",
            "Sexual Abuse",
            "Suicide",
            "Victim of Crime",
          ],
        },
        {
          type: "tagbox",
          name: "personalSubCategories",
          visibleIf:
            "{mainReason} contains ['Personal Problems and Life Stressors']",
          title:
            "Please select which type of Personal Problems and Life Stressors issues you work with?",
          requiredIf:
            "{mainReason} contains ['Personal Problems and Life Stressors']",
          choices: [
            "Anger Management",
            "Assertiveness Training",
            "Behaviour Problems",
            "Body Image",
            "Carer Support",
            "Grief and Loss",
            "Infertility Issues",
            "Emotion dysregulation",
            "Life Transition and Adjustment Issues",
            "Sport Performance",
            "Pregnancy Support",
            "Parenting Support",
            "Religious Issues",
            "Self Esteem & Self Development",
            "Sexual Difficulties",
            "Social Skills",
            "Stress management",
          ],
        },
        {
          type: "tagbox",
          name: "genderSubCategories",
          visibleIf: "{mainReason} contains ['Gender']",
          title: "Please select which type of Gender issues you work with?",
          requiredIf: "{mainReason} contains ['Gender']",
          choices: [
            "Diverse Genders",
            "Diverse Sexualities",
            "Gender Dysphoria",
          ],
        },
        {
          type: "tagbox",
          name: "relationshipSubCategories",
          visibleIf: "{mainReason} contains ['Relationships']",
          title:
            "Please select which type of Relationship issues you work with?",
          requiredIf: "{mainReason} contains ['Relationships']",
          choices: [
            "Conflict Resolution",
            "Couples Therapy",
            "Divorce/Separation",
            "Family Therapy",
            "Mediation",
            "Parenting",
            "Relationships",
            "Attachment disruption (fostering and adoption)",
          ],
        },
        {
          type: "tagbox",
          name: "educationalSubCategories",
          visibleIf: "{mainReason} contains ['Educational']",
          title:
            "Please select which type of Educational issues you work with?",
          requiredIf: "{mainReason} contains ['Educational']",
          choices: [
            "Academic Performance",
            "Intellectual Disability",
            "Learning Difficulties",
            "School Refusal",
            "Problems with peers",
            "Specific Learning Disorder",
            "Developmental Delays",
            "School Attendance Issues",
          ],
        },
        {
          type: "tagbox",
          name: "addictionSubCategories",
          visibleIf: "{mainReason} contains ['Addictions']",
          title: "Please select which type of Addiction issues you work with?",
          requiredIf: "{mainReason} contains ['Addictions']",
          choices: [
            "Alcohol dependence",
            "Drug dependence",
            "Gambling",
            "Impulsive Behaviours",
            "Internet Gaming / Social Networking",
            "Pornography / Sex Addiction",
            "Smoking",
          ],
        },
        {
          type: "tagbox",
          name: "workSubCategories",
          visibleIf: "{mainReason} contains ['Work/Community']",
          title:
            "Please select which type of Work/Community issues you work with?",
          requiredIf: "{mainReason} contains ['Work/Community']",
          choices: [
            "Employee Assistance Program",
            "Human Resource Management",
            "Leadership",
            "Organisational Restructuring",
            "Military, Police, & Emergency Work",
            "Performance Management",
            "Personnel Selection",
            "Work Stress",
            "Workplace Bullying",
            "Executive Coaching",
            "Workplace Accident",
          ],
        },
        {
          type: "tagbox",
          name: "legalSubCategories",
          visibleIf: "{mainReason} contains ['Legal']",
          title: "Please select which type of Legal issues you work with?",
          requiredIf: "{mainReason} contains ['Legal']",
          choices: [
            "Criminal Behaviour",
            "Expert Witness",
            "Family Court Issues",
            "Legal Report",
            "Management of Offenders",
            "Sex Offenders",
          ],
        },
        {
          type: "tagbox",
          name: "subPersonalityDisorders",
          visibleIf:
            "{mentalHealthSubCategories} contains 'Personality Disorders'",
          title:
            "Please select which type of Personality Disorder issues you work with?",
          requiredIf:
            "{mentalHealthSubCategories} contains 'Personality Disorders'",
          choices: [
            "Borderline Personality Disorder ",
            "Narcissistic Personality Disorder",
            "Antisocial Personality Disorder",
            "Paranoid Personality Disorder",
            "Dependent Personality Disorder ",
            "Obsessive Compulsive Personality Disorder",
          ],
        },
        {
          type: "tagbox",
          name: "eatingSubCategories",
          visibleIf: "{mentalHealthSubCategories} contains 'Eating Disorders'",
          title:
            "Please select which type of Eating Disorder issues you work with?",
          requiredIf: "{mentalHealthSubCategories} contains 'Eating Disorders'",
          choices: [
            "Anorexia Nervosa",
            "Bulimia Nervosa ",
            "Binge eating",
            "Pica (eating non-food substances)",
            "Avoidant/Restrictive Food Intake",
          ],
        },
        {
          type: "tagbox",
          name: "anxietySubCategories",
          visibleIf: "{mentalHealthSubCategories} contains 'Anxiety & Phobias'",
          title:
            "Please select which type of Anxiety and Phobia issues you work with?",
          requiredIf:
            "{mentalHealthSubCategories} contains 'Anxiety & Phobias'",
          choices: [
            "General Anxiety",
            "Social Anxiety",
            "Specific Phobias",
            "Agoraphobia (difficulty being outside in public spaces)",
            "Attachment anxiety (children)",
          ],
        },
        {
          type: "checkbox",
          name: "whoPays",
          title: "What types of clients types are you looking to service",
          isRequired: true,
          choices: [
            "Self funded",
            "Self (with Medicare Rebate)",
            "Self (with private insurance rebate)",
            "Department of Veterans Affairs (DVA)",
            "National Disability Insurance Scheme (NDIS)",
            "WorkSafe",
            "Workcover",
            "Transport Accident Commission (TAC)",
            "Victims of Crime",
            "Employer Funded (Employee Assistance Program)",
            "Open Arms / BUPA ADF Health Services",
            "Women’s Resource Information and Support Centre (WRISC)",
            "Chronic Disease Management Plan",
            "Enhanced Primary Care Plan",
            "Team Care Arrangement"
          ],
          otherText: "Other (please specify)",
        },
        {
          type: "boolean",
          name: "regsiteredWithWorkSafe",
          visibleIf: "{whoPays} = ['WorkSafe']",
          title: "Are you registered with WorkSafe?",
          requiredIf: "{whoPays} = ['WorkSafe']",
        },
        {
          type: "boolean",
          name: "registeredWithDVA",
          visibleIf: "{whoPays} = ['Department of Veterans Affairs (DVA)']",
          title: "Are you registered with DVA?",
        },
        {
          type: "boolean",
          name: "registeredWithTAC",
          visibleIf: "{whoPays} = ['Transport Accident Commission (TAC)']",
          title: "Are you registered with TAC?",
          requiredIf: "{whoPays} = ['Transport Accident Commission (TAC)']",
        },
        {
          type: "boolean",
          name: "telehealthPreference",
          title: "Would you be willing to see a client via Telehealth?",
          isRequired: true,
          description: "Telehealth services might be available sooner.",
        },
        {
          type: "paneldynamic",
          name: "availPanel",
          title: "What days/times/locations do you offer appointments?",
          description: "Click the 'Add' button to add your availability",
          isRequired: true,
          templateElements: [
            {
              type: "radiogroup",
              name: "location",
              title: "Location",
              isRequired: true,
              choices: [
                {
                  value: "Location A",
                  text: "Bacchus Marsh",
                },
                {
                  value: "Location B",
                  text: "Ballarat",
                },
                {
                  value: "Location C",
                  text: "Geelong",
                },
                "Telehealth",
              ],
            },
            {
              type: "radiogroup",
              name: "day",
              title: "Day ",
              isRequired: true,
              choices: [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday ",
                "Friday",
                "Saturday",
                "Sunday",
              ],
            },
            {
              type: "panel",
              name: "question12",
              elements: [
                {
                  type: "tagbox",
                  name: "timeOfDay",
                  title: "Times of day",
                  isRequired: true,
                  choices: [
                    {
                      value: "Morning",
                      text: "Morning (8-12)",
                    },
                    {
                      value: "Early Afternoon",
                      text: "Early Afternoon (12-3)",
                    },
                    {
                      value: "Late Afternoon",
                      text: "Late Afternoon (3-6)",
                    },
                    {
                      value: "Evening",
                      text: "Evening (6-8)",
                    },
                  ],
                },
              ],
              isRequired: true,
            },
          ],
        },
        {
          type: "boolean",
          name: "legalMatters",
          title:
            "Are you open to working with clients involved in any legal matters or cases?",
          isRequired: true,
        },
        {
          type: "checkbox",
          name: "therapies",
          // visibleIf: "{service} contains ['Assessments']",
          title: "Do you specialise in any of these therapies?",
          isRequired: true,
          colCount: 2,
          // requiredIf: "{service} contains ['Assessments']",
          visibleIf: "{service} contains ['Psychology']",
          choices: [
            "Cognitive Behaviour Therapy (CBT)",
            "Schema Therapy",
            "Acceptance and Commitment Therapy (ACT)",
            "Interpersonal Psychotherapy (IPT)",
            "Eye Movement Desensitisation and Reprocessing Therapy EMDR)",
            "Cognitive Processing Therapy",
            "Solution Focused Therapy",
            "Coaching",
            "Psychodynamic Therapy ",
            "Dialectical Behaviour Therapy",
            "Play Therapy",
            "Emotion Focused Therapy (EFT)",
            "ABA Therapy",
            "Mindfulness Based Cognitive Therapy (MBCT) ",
            " Cognitive Based Therapy Eating (CBT-E)",
            "Family Based Therapy (FBT) ",
            "Animal Assisted Therapy",
            "Motivational Interviewing",
            "Drug and Alcohol Counselling",
            "Couples Therapy",
            "Family Therapy",
            "Sex Therapy",
            "Group Therapy",
            "Compassion Focused Therapy (CFT)",
            "Strengths Based Therapy",
            "Sport Performance",
            "Cognitive-Behavioural Intergrated Therapy (C-BIT)",
            "Neuroaffirming",
          ],
        },
        {
          type: "comment",
          name: "strongInterest",
          title:
            "Please let us know if there are any particular presentations or areas that you have a strong interest in. ",
        },
        {
          type: "comment",
          name: "wontWork",
          title:
            "Please let us know if there are particular presentations or areas that you do not want to work with. ",
        },
      ],
    },
  ],
};

export default psychFormJson;
