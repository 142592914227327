/***
 *
 *   MATCHING FORM
 *   SHOW MATCHING DETAILS
 *
 **********/

import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  Row,
  useAPI,
  Card,
  Grid,
  ViewContext,
  Icon,
  Link,
  Button,
} from "components/lib";
import { useParams, useNavigate } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { CheckCircle, Cancel } from "@mui/icons-material";
import { Box } from "@mui/material";
import axios, { Axios } from "axios";
import ReactQuill from "react-quill";
import Tooltip from '@mui/material/Tooltip';
import "react-quill/dist/quill.snow.css";

export function Match(props) {
  const { id } = useParams();
  const matchesAPI = useAPI(`/api/match/client/${id}`);
  const [matches, setMatches] = React.useState([]);
  const psychUsers = useAPI("/api/psych_users");
  const [type, searchType] = React.useState("");
  const clientAPI = useAPI(`/api/client/${id}`);
  const accountAPI = useAPI(`/api/account`);
  const context = useContext(ViewContext);
  const [value, setValue] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [replyTo, setReplyTo] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [options, setOptions] = useState([{ id: 1, name: "test-loca" }]);
  const [name, setName] = useState("");
  const [emails, setEmails] = useState("");
  const templates = useAPI("/api/template");
  const emailsAPI = useAPI("/api/client/emails/" + id);

  useEffect(() => {
    if (emailsAPI?.data && !emailsAPI?.loading) {
      console.log("emailsAPI", emailsAPI);
      setEmails(emailsAPI.data);
    }
  }, [emailsAPI]);

  useEffect(() => {
    if (clientAPI?.data && !clientAPI?.loading) {
      console.log("clientAPI", clientAPI);
      console.log(
        "clientAPI?.data?.[0]?.data?.service",
        clientAPI?.data?.[0]?.data?.service
      );
      searchType(clientAPI?.data?.[0]?.data?.service);
      setValue(clientAPI?.data?.[0]?.comment);
      setName(clientAPI?.data?.[0]?.name);
    }
  }, [clientAPI]);

  useEffect(() => {
    if (matchesAPI?.data && !matchesAPI?.loading && psychUsers?.data) {
      const updatedMatches = matchesAPI.data.map((match) => {
        console.log("psychUsers", psychUsers.data);
        const psychUser = psychUsers.data.find(
          (user) => user.id === match.user_id
        );
        console.log("psychUser", psychUser, match);
        return {
          ...match,
          count:
            Number(match.presenting_issues) +
            Number(match.assessment) +
            Number(match.payment_type) +
            Number(match.age) +
            Number(match.location) +
            Number(match.availability),
          psych_user_name: psychUser?.name,
          psych_id: psychUser?.id,
          current_match: clientAPI?.data?.[0]?.user_id === psychUser?.id,
        };
      });

      const filteredMatches = updatedMatches.filter(
        (match) =>
          (match.presenting_issues || match.assessment) &&
          match.age &&
          match.payment_type
      );
      console.log("filteredMatches", filteredMatches);
      console.log("updatedMatches", updatedMatches);
      setMatches(filteredMatches);
    }
  }, [matchesAPI, psychUsers]);

  useEffect(() => {
    if (templates?.data && !templates?.loading) {
      console.log("templates", templates);
      setOptions(templates.data);
    }
  }, [templates]);

  useEffect(() => {
    const getTemplate = async () => {
      const template = await axios.get(`/api/template/${selectedOption}`);
      console.log("template", template);
      setSubject(template.data.data[0].subject);
      setBody(template.data.data[0].body);
      setReplyTo(template.data.data[0].reply_to);
    };

    if (!selectedOption) return;
    console.log("selectedOption", selectedOption);
    const template = getTemplate();
  }, [selectedOption]);

  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "id",
      //   header: "ID",
      //   sortable: true,
      // },
      {
        // accessorKey: "psych_user_name",
        accessorFn: (row) => (
          <a href={`/account/user/${row.user_id}`}>{row.psych_user_name}</a>
        ),
        Header: () => (
          <Tooltip title="Clinician-to-referral matching will only occur if there is a 100% match on presenting issues, referral type, and age. If there have been recent changes made to your clinician preferences, you can manually refresh matches by locating and clicking this icon">
            <span>Clinician</span>
          </Tooltip>
        ),
        // header: "Clinician",
        id: "psych_user_name",
        size: 40,
        sortable: true,
      },
      {
        accessorFn: (row) => {
          return row.presenting_issues ? (
            <CheckCircle />
          ) : (
            <Cancel color="error" />
          );
        },
        header: "Presenting Issues",
        Header: (
          <>
            <p>Presenting</p>
            <p>Issues</p>
          </>
        ),
        sortable: true,
        id: "presenting_issues",
        size: 30,
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "count",
        header: "Count",
        id: "count",
      },
      {
        accessorFn: (row) => {
          return row.assessment ? <CheckCircle /> : <Cancel color="error" />;
        },
        header: "Assessments",
        id: "assessments",
        sortable: true,
        size: 30,
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        accessorFn: (row) => {
          return row.payment_type ? <CheckCircle /> : <Cancel color="error" />;
        },
        header: "Referral Type",
        id: "referral_type",
        Header: (
          <>
            <p>Referral</p>
            <p>Type</p>
          </>
        ),
        sortable: true,
        size: 30,
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        accessorFn: (row) => {
          return row.age ? <CheckCircle /> : <Cancel color="error" />;
        },
        header: "Age",
        id: "age",
        sortable: true,
        size: 30,
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        accessorFn: (row) => {
          return row.location ? <CheckCircle /> : <Cancel color="error" />;
        },
        header: "Location",
        id: "location",
        sortable: true,
        size: 30,
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        accessorFn: (row) => {
          return row.availability ? <CheckCircle /> : <Cancel color="error" />;
        },
        header: "Availability",
        id: "availability",
        sortable: true,
        size: 30,
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      },
    ],
    []
  );

  const handleSave = () => {
    console.log("save");
    axios.post(`/api/client/comment/${id}`, {
      comment: value,
    });
    context.notification.show(
      "Comment has been saved.",
      "success",
      true,
      "toast",
      "heart"
    );
  };

  const handleMatch = (match) => {
    console.log("match", match);
    context.notification.show(
      "Match has been sent to the clinician.",
      "success",
      true,
      "toast",
      "heart"
    );
    // match user - client id, user id, assign_info
    const matchResponse = axios.post("/api/client/match", {
      id: id,
      user_id: match.psych_id,
      assign_info: {},
    });

    const updatedMatches = matches.map((m) => {
      if (id === m.id) {
        return {
          ...m,
          current_match: true,
        };
      }
      return m;
    });
    setMatches(updatedMatches);
    window.location = `/match/${id}`;
  };

  const archive = () => {
    console.log("archive");
    context.modal.show(
      {
        title: `Archive this client?`,
        form: {},
        buttonText: "Archive",
        text: `Are you sure?`,
        url: `/api/client/archive/${id}`,
        method: "delete",
      },
      (res) => {
        // console.log("in cb", res);
        console.log("res", res);
        window.location = "/dashboard";
      }
    );
  };

  const match = async () => {
    console.log("in match");
    // trigger checks
    await axios.get(`/api/match/trigger/${id}`);
    // reload or get redirected from BE
    window.location = `/match/${id}`;
  };

  const handleSend = async () => {
    console.log("send", clientAPI);
    const response = await axios.post(`/api/utility/send`, {
      to: clientAPI?.data?.[0]?.data?.email,
      reply_to: replyTo,
      subject: subject,
      body: body.replace(/{{name}}/g, clientAPI?.data?.[0]?.name),
      id: id,
    });
    console.log("response", response);
    context.notification.show(
      "Email has been sent.",
      "success",
      true,
      "toast",
      "heart"
    );
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleManual = () => {
    console.log("manual");
    context.modal.show(
      {
        title: `Assign this client to a clinician?`,
        form: {
          user_id: {
            type: "select",
            required: true,
            options: psychUsers?.data?.map((u) => {
              return {
                value: u.id,
                label: u.name,
              };
            }),
          },
        },
        buttonText: "Assign",
        text: `Are you sure?`,
        url: `/api/client/assign/${id}`,
        method: "patch",
      },
      (res) => {
        // console.log("in cb", res);
        console.log("res", res);
        window.location = `/match/${id}`;
      }
    );
  };

  const handleClear = async () => {
    console.log("clear");
    const matchResponse = await axios.patch(`/api/client/clear/${id}`);
    window.location = `/client/${id}`;
  };

  const emailColumns = useMemo(
    () => [
      {
        accessorFn: (row) => row?.info?.subject || "No Subject",
        header: "Subject",
        id: "subject",
      },
      {
        accessorFn: (row) => row?.info?.reply_to || "No Reply To",
        header: "Reply To",
        id: "reply_to",
      },
      {
        accessorFn: (row) => row.created_at,
        header: "Created At",
        id: "created_at",
      },
    ],
    []
  );

  return (
    <>
      <Grid cols={5}>
        <Card>
          <a href={`/dashboard`}>
            <Icon
              color="dark"
              image="arrow-left"
              size={20}
              className="absolute top-1/2 left-4 -translate-y-1/2"
            />
          </a>
          <div className="relative text-center min-h-max">
            <p className="text-2xl text-sky-500 font-bold">
              {/* <Button text="View" textOnly /> */}
              {/* <Link url={`/client/${id}`}>View</Link> */}
              {name}
            </p>
            <p className="text-sm text-slate-400">
              <Link url={`/client/${id}`}>View</Link>
            </p>
          </div>
        </Card>
        <Card>
          <Icon
            color="dark"
            image="alert-triangle"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2"
          />
          <div className="relative text-center min-h-max">
            <p className="text-2xl font-bold">{matches.length}</p>
            <p className="text-sm text-slate-400">Total Matches</p>
          </div>
        </Card>
        <Card>
          <Icon
            color="dark"
            image="stop-circle"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2"
          />
          <div className="relative text-center min-h-max">
            <p className="text-2xl font-bold">
              {clientAPI?.data?.[0]?.user_id ? "Yes" : "No"}
            </p>
            <p className="text-sm text-slate-400">
              Assigned? (
              <Button
                text="clear"
                textOnly
                className="underline"
                action={handleClear}
              />
              ) (
              <Button
                text="manual"
                textOnly
                className="underline"
                action={handleManual}
              />
              )
            </p>
          </div>
        </Card>
        {clientAPI?.data?.[0]?.user_id && (
          <Card>
            <Icon
              color="dark"
              image="user-check"
              size={20}
              className="absolute top-1/2 left-4 -translate-y-1/2"
            />

            <div className="relative text-center min-h-max">
              <p className="text-2xl font-bold">
                {
                  psychUsers?.data?.find(
                    (u) => u.id === clientAPI?.data?.[0]?.user_id
                  )?.name || "No Name"
                }
              </p>
            </div>
          </Card>
        )}
        <Card>
          <Icon
            color="dark"
            image="calendar"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2"
          />
          <div className="relative text-center min-h-max">
            <p className="text-2xl font-bold">
              {clientAPI?.data?.[0]?.created_at.split("T")[0]}
            </p>
            <p className="text-sm text-slate-400">Date</p>
          </div>
        </Card>

        {accountAPI?.data?.settings?.coreplusIntegration?.enabled && <Card>
          <Icon
            color="dark"
            image="share"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2"
          />
          <div className="relative text-center min-h-max">
            <p className="text-2xl font-bold text-sky-500">
              <Link text="Send" onClick={() => {
                console.log("send");
                context.modal.show(
                  {
                    title: `Export this client to a Coreplus?`,
                    form: {
                    },
                    buttonText: "Export",
                    text: `Are you sure?`,
                    url: `/api/external_clients/coreplus/${id}`,
                    method: "post",
                  },
                  (res) => {
                    // console.log("in cb", res);
                    console.log("res", res);
                  }
                );
              }}/>
            </p>
            <p className="text-sm text-slate-400">Coreplus</p>
          </div>
        </Card>}

        {/* <Card>Status</Card>
        <Card>Urgent</Card>
        <Card>Matches</Card> */}
      </Grid>
      {type && (
        <MaterialReactTable
          title="Matches"
          columns={columns}
          data={matches}
          enableRowActions
          // enableSelectAll
          enableColumnVisibility
          muiTableBodyRowProps={(row) => {
            if (row.row.original.current_match) {
              return {
                style: {
                  backgroundColor: "#D6EAF8",
                },
              };
            }
          }}
          // enableRowSelection={true}
          initialState={{
            columnVisibility: {
              assessments: type === "Assessments" ? true : false,
              presenting_issues: type !== "Assessments" ? true : false,
              count: false,
            },
            sorting: [{ id: "count", desc: true }],
          }}
          renderRowActions={(row) => {
            return (
              <>
                <Button
                  text="Assign"
                  small
                  action={handleMatch}
                  params={row.row.original}
                />
              </>
            );
          }}
          positionActionsColumn="last"
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
                marginLeft: "auto",
              }}
            >
              <Button
                action={archive}
                text="Archive"
                icon="archive"
                small
                key="delete"
              />
              <Button
                action={match}
                icon="crosshair"
                text="Match"
                small
                key="match"
              />
            </Box>
          )}
        />
      )}
      <br />
      <br />
      <Grid cols="2">
        <Card className="min-h-100%">
          <h1 className="text-lg font-bold">Add or Edit Comment</h1>
          <ReactQuill theme="snow" value={value} onChange={setValue} />
          <br />
          <Button text="Save" action={handleSave} />
        </Card>
        <Card>
          <h1 className="text-lg font-bold">Email Client</h1>
          <h2>Select a template</h2>
          <div>
            <select
              value={selectedOption}
              onChange={handleOptionChange}
              className="border border-gray-300 p-2 rounded mt-2"
            >
              <option value="">Select an option</option>
              {options.map((o) => (
                <option key={o.id} value={o.id}>
                  {o.name}
                </option>
              ))}
            </select>
          </div>
          <br />
          <h3>Reply To</h3>
          <h4>One way email capability. Please add a reply to email.</h4>
          <input
            className={"border border-gray-300 p-2 rounded mt-2 w-full"}
            type="text"
            value={replyTo}
            onChange={(e) => setReplyTo(e.target.value)}
          />
          <h3>Subject</h3>
          <input
            className={"border border-gray-300 p-2 rounded mt-2 w-full"}
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <br />
          <h3>Body</h3>
          <p className="text-sm text-gray-600 italic">To add a prospective clients name into the template please enter the following dynamic term {"{{name}}"}</p>
          <ReactQuill value={body} onChange={setBody} />
          <br />
          <Button text="Send" action={handleSend} />
        </Card>
      </Grid>
      <Card>
        <h1 className="text-lg font-bold">Email History</h1>
        <MaterialReactTable
          title="Emails"
          columns={emailColumns}
          data={emails}
          renderDetailPanel={(row) => {
            console.log("row", row);
            return (
              <div className="p-4">
                <h3>Body</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: row.row.original?.info?.body || "No Body",
                  }}
                />
              </div>
            );
          }}
        />
      </Card>
    </>
  );
}
